import React, { useState } from "react";
import emailjs from "emailjs-com";
import styles from "./ContactBlock.module.css";

const ContactBlock = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    consent: false,
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({ text: "", type: "" });
  const [visible, setVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    setErrors({ ...errors, [name]: false });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = true;
    if (!formData.phone) newErrors.phone = true;
    if (!formData.email) newErrors.email = true;
    if (!formData.company) newErrors.company = true;
    if (!formData.consent) newErrors.consent = true;
    return newErrors;
  };

  const handleButtonClick = (e) => {
    const button = e.target;
    button.classList.add(styles.animate);

    // Удаляем класс после завершения анимации
    setTimeout(() => {
      button.classList.remove(styles.animate);
    }, 200); // Длительность анимации в миллисекундах
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleButtonClick(e); // Запуск анимации кнопки

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    emailjs
      .send("service_zxhoeep", "template_qiino6b", formData, "vnzVEGwklsuPUCgLU")
      .then(
        () => showMessage("Ваше сообщение успешно отправлено!", "success"),
        () => showMessage("Не удалось отправить сообщение. Попробуйте позже.", "error")
      );

    setFormData({
      name: "",
      phone: "",
      email: "",
      company: "",
      consent: false,
    });
    setErrors({});
  };

  const showMessage = (text, type) => {
    setMessage({ text, type });
    setVisible(true);
    setTimeout(() => setVisible(false), 5000);
  };

  return (
    <div className={styles.contactBlock} id="ask">
      <h1 className={styles.contactTitle}>Оставить заявку</h1>
      <h2 className={styles.contactSubtitle}>
        Оставьте свои контакты и наши менеджеры свяжутся с вами
      </h2>
      <form onSubmit={handleSubmit} className={styles.contactForm}>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Имя"
          className={`${styles.input} ${errors.name ? styles.errorInput : ""}`}
        />
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Номер телефона"
          className={`${styles.input} ${errors.phone ? styles.errorInput : ""}`}
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Электронная почта"
          className={`${styles.input} ${errors.email ? styles.errorInput : ""}`}
        />
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
          placeholder="Название компании"
          className={`${styles.input} ${errors.company ? styles.errorInput : ""}`}
        />
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            name="consent"
            checked={formData.consent}
            onChange={handleChange}
            className={styles.checkboxInput}
          />
          <label
            className={`${styles.checkboxLabel} ${
              errors.consent ? styles.errorLabel : ""
            }`}
          >
            Соглашаюсь с условиями использования персональных данных
          </label>
        </div>
        <button
          type="submit"
          className={styles.submitButton}
          onClick={handleButtonClick}
        >
          Отправить
        </button>
      </form>

      {visible && (
        <div
          className={`${styles.notification} ${
            message.type === "success"
              ? styles.successNotification
              : styles.errorNotification
          } ${visible ? styles.show : ""}`}
        >
          <span className={styles.icon}>
            {message.type === "success" ? "✅" : "❌"}
          </span>
          {message.text}
        </div>
      )}
    </div>
  );
};

export default ContactBlock;
